import type { ImageSize } from "api/hooks/useImageResolver";
import { useImageResolver } from "api/hooks/useImageResolver";
import { Carousel } from "components/Carousel/Carousel";
import { type FormImage, isImageUploaded } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { VideoPreview } from "components/VideoPreview/VideoPreview";
import type { AnimationProps } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { preloadImage } from "helpers/image";
import { useKey } from "hooks/useKey";
import type React from "react";
import { useState } from "react";
import ReactDOM from "react-dom";
import { twJoin } from "tailwind-merge";

const MAX_AMOUNT_IMAGE_SHOWN = 5;

const commonAnimProps: AnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

interface CommunityItemGalleryProps {
  images: FormImage[];
  shouldLoad?: boolean;
}

export function CommunityItemGallery({ images, shouldLoad = true }: CommunityItemGalleryProps): React.ReactNode {
  const [zoomedImage, setZoomedImage] = useState<FormImage | undefined>(undefined);

  useKey(
    "Escape",
    () => {
      setZoomedImage(undefined);
    },
    Boolean(zoomedImage),
  );

  let partitionedImages = [images];
  if (images.length > 3) {
    partitionedImages = [images.slice(0, 2), images.slice(2, MAX_AMOUNT_IMAGE_SHOWN)];
  }

  return (
    <>
      <div className="flex w-full flex-col gap-2" data-testid="images-attachment">
        {partitionedImages.map((imageGroup, imageGroupIndex) => (
          <div key={imageGroupIndex} className="flex w-full gap-2">
            {imageGroup.map((currImage, imageIndex) => {
              const isSingle = images.length === 1;
              const isLast =
                isSingle || (imageGroupIndex === partitionedImages.length - 1 && imageIndex === imageGroup.length - 1);
              // The images in the first row are primary -> Larger thumbnail
              const isPrimary = imageGroupIndex === 0;

              return (
                <CommunityItemGalleryImage
                  key={currImage.url}
                  image={currImage}
                  amountImagesAfter={images.length - MAX_AMOUNT_IMAGE_SHOWN}
                  onClick={setZoomedImage}
                  {...{ shouldLoad, isPrimary, isLast, isSingle }}
                />
              );
            })}
          </div>
        ))}
      </div>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {zoomedImage && (
            <motion.div className="fixed inset-0 z-50 flex min-h-screen scale-100 items-center justify-center overflow-y-auto">
              <motion.div
                className="absolute inset-0 z-0 bg-black/80"
                role="button"
                onClick={() => setZoomedImage(undefined)}
                {...commonAnimProps}
              />
              {images.length === 1 && (
                <motion.img
                  className="z-10 w-5/6 cursor-pointer select-none overflow-hidden rounded-lg object-cover sm:max-h-[80%] sm:w-auto sm:max-w-[80%]"
                  src={zoomedImage.url}
                  alt={"description" in zoomedImage ? zoomedImage.description || "" : ""}
                  onClick={() => setZoomedImage(undefined)}
                  {...commonAnimProps}
                />
              )}
              {images.length > 1 && (
                <motion.div
                  className="pointer-events-none relative flex aspect-[3/4] w-5/6 items-center sm:h-4/5 sm:w-auto"
                  {...commonAnimProps}
                >
                  <div className="pointer-events-auto w-full overflow-hidden rounded-lg">
                    <Carousel
                      defaultPage={images.indexOf(zoomedImage)}
                      styling="overlay"
                      objectFit="contain"
                      {...{ images }}
                    />
                  </div>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>,
        document.body,
      )}
    </>
  );
}

interface CommunityItemGalleryImageProps {
  image: FormImage;
  isPrimary: boolean;
  isLast: boolean;
  isSingle: boolean;
  shouldLoad: boolean;
  amountImagesAfter: number;
  onClick: (image: FormImage) => void;
}

export function CommunityItemGalleryImage({
  image,
  isPrimary,
  isLast,
  isSingle,
  shouldLoad,
  amountImagesAfter,
  onClick,
}: CommunityItemGalleryImageProps): React.ReactNode {
  const [isLoading, setIsLoading] = useState(true);

  const resolveImage = useImageResolver();

  const onMouseEnterImage = (image: FormImage) => {
    preloadImage(image.url);
  };

  const isUploaded = isImageUploaded(image);
  let thumbnailSize: ImageSize = isPrimary ? "lg" : "md";
  if (isSingle) {
    thumbnailSize = "intrinsic";
  }

  return (
    <button
      type="button"
      onClick={() => onClick(image)}
      onMouseEnter={isUploaded ? () => onMouseEnterImage(image) : undefined}
      className={twJoin(
        "relative flex flex-1 cursor-zoom-in items-center justify-center overflow-hidden rounded-lg border-[0.5px] border-grey-100",
        isSingle ? "aspect-video" : "aspect-square",
      )}
    >
      {isLast && amountImagesAfter > 0 && (
        <div className="absolute left-0 top-0 flex size-full items-center justify-center bg-black/60 transition-colors hover:bg-black/80">
          <span className="text-headline3 font-old-bold text-white">+{amountImagesAfter}</span>
        </div>
      )}
      {isLoading && <CommunityItemContentSkeleton />}
      {shouldLoad && (
        <img
          className={twJoin(
            "min-h-full min-w-full object-cover object-center",
            isLoading ? "opacity-0" : "opacity-100 transition-opacity hover:opacity-90",
          )}
          src={isUploaded ? resolveImage(image, thumbnailSize) : image.url}
          alt={"description" in image ? image.description || "" : ""}
          onLoad={() => setIsLoading(false)}
        />
      )}
    </button>
  );
}

interface CommunityItemVideoProps {
  video: FormVideo;
  shouldLoad?: boolean;
}

export const CommunityItemVideo = ({ video }: CommunityItemVideoProps): React.ReactNode => {
  // TODO: Add back once BE supports thumbnail
  // const [isLoading, setIsLoading] = useState(true);

  // return (
  //   <div className="relative aspect-video w-full overflow-hidden rounded-md bg-black">
  //     {isLoading && <CommunityItemContentSkeleton />}
  //     {shouldLoad && (
  //       <video
  //         onLoadedMetadata={() => setIsLoading(false)}
  //         className="aspect-video w-full"
  //         src={video.url}
  //         controls={!isLoading}
  //       />
  //     )}
  //   </div>
  // );

  return <VideoPreview video={video} />;
};

const CommunityItemContentSkeleton = () => {
  return (
    <span
      style={{
        backgroundSize: "200%",
      }}
      className="absolute left-0 top-0 z-10 size-full animate-background-shift-x bg-grey-100 bg-gradient-to-r from-grey-100 via-grey-100 to-grey-300"
    />
  );
};
