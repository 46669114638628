import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { SelfUpdateRequest } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { routes } from "routes";

import type { FormValues, LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const api = useApi();
  const queryClient = useQueryClient();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uploadFormImage, isUploadingImage } = useUploadImage();

  const { mutate, isPending: isSubmitting } = useMutation({
    mutationFn: (payload: SelfUpdateRequest) => api.putSelfV3(payload),
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    onError() {
      showFlashToast({ title: t("page.onboarding.user-details.submit.error"), type: "error" });
    },
    onSuccess() {
      navigate(routes.onboardingFlow.groups({ slug }));
    },
  });

  if (sessionUser.onboardedAt) {
    return <Navigate to="/" />;
  }

  return props.children({
    defaultValues: {
      firstName: sessionUser.firstName,
      lastName: sessionUser.lastName,
      biography: undefined,
      company: sessionUser.company?.name,
      occupation: undefined,
    },
    isCompanyProject: sessionUser.project.type === "companyBased",
    async onSubmit(formValues: FormValues) {
      let payload: SelfUpdateRequest = {
        ...formValues,
        languageId: sessionUser.language.id,
        hideEmail: sessionUser.emailHidden,
        hidePhoneNumber: sessionUser.phoneNumberHidden,
        hideHouseNumber: sessionUser.hideHouseNumber,
        enableChat: sessionUser.chatEnabled,
      };

      if (formValues.avatar && formValues.avatar.length > 0) {
        const avatarImage = await uploadFormImage(formValues.avatar?.[0]);

        payload = { ...payload, avatarId: avatarImage?.id };
      }

      mutate(payload);
    },
    isSubmitting: isSubmitting || isUploadingImage,
  });
}
