import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { UserDto } from "api/types";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { FileUploadButton } from "components/Button/FileUploadButton";
import { IconButton } from "components/Button/IconButton";
import { ErrorPage } from "components/Error/ErrorPage";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormSearchableSelect } from "components/Form/FormSearchableSelect";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { formatAddress } from "helpers/address";
import { parseFileContent } from "helpers/file-parse";
import { useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProject } from "hooks/Network/useProject";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnDropFiles } from "hooks/useOnDropFiles";
import { useOnPaste } from "hooks/useOnPaste";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DataTable } from "../components/DataTable";
import type { Data } from "../helpers/parser";
import { parseData } from "../helpers/parser";
import { downloadExcel, parseExcel } from "../helpers/sheet";
import { validate } from "../helpers/validation";

type FormValues = {
  signOffUser: UserDto;
};

export function UserBulkUpload(): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const api = useApi();
  const project = useProject();
  const [isUploading, setIsUploading] = useState(false);
  const [isParsingFile, setIsParsingFile] = useState(false);
  const [data, setData] = useState<Data<NonNullable<typeof columns>>>();
  const { mutateAsync: uploadUserAsync } = useMutation({ mutationFn: api.postUsersV1 });
  const { data: languages, isFetching: isLoadingLanguages, error: languagesError } = useLanguages();
  const formMethods = useForm<FormValues>({
    mode: "onChange",
  });
  const {
    data: admins = [],
    isLoading: isLoadingAdmins,
    error: adminsError,
  } = useQuery({
    queryKey: QUERY_KEYS.USERS_ADMINS(projectId),
    queryFn: () => api.getUsersAdminsV1(),
    select: commonAPIDataSelector,
    enabled: sessionUser.isSuperAdmin,
  });
  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    error: addressesError,
  } = useQuery({
    queryKey: QUERY_KEYS.ADDRESS_LIST(projectId),
    queryFn: () => api.getAddressesV1(),
    select: commonAPIDataSelector,
    enabled: project.type === "addressBased",
  });
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companiesError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMPANY_LIST(projectId),
    queryFn: () => api.getCompaniesV1({ Limit: 10000, Offset: 0 }),
    select: commonAPIDataSelector,
    enabled: project.type === "companyBased",
  });

  const usersQueryArgs = {
    Offset: 0,
    Limit: 10000,
    IncludeAdmins: true,
    IncludeDeleted: false,
    IncludeNotRegistered: true,
  };
  const {
    data: users,
    isPending: isLoadingUsers,
    error: usersError,
  } = useQuery({
    queryKey: QUERY_KEYS.USERS_QUERY(projectId, usersQueryArgs),
    queryFn: () => api.getUsersV1(usersQueryArgs),
    select: (data) => commonAPIDataSelector(data).items,
  });

  const columns = useMemo(() => {
    if (
      (project.type === "addressBased" && !isDefined(addresses)) ||
      (project.type === "companyBased" && !isDefined(companies)) ||
      !isDefined(users) ||
      !isDefined(languages)
    ) {
      return undefined;
    }

    const languageMap = new Map(languages.map((x) => [x.id, x.description]));

    const columns = [
      project.type === "addressBased"
        ? {
            name: "Address",
            alias: ["adres", "streetname", "straatnaam", "straat", "street"],
            rules: {
              required: t("page.user-bulk-upload.validation.address.required"),
              oneOf: {
                error: t("page.user-bulk-upload.validation.address.unknown"),
                values: addresses!.items.map(formatAddress),
              },
            },
            warnings: {
              unique: t("page.user-bulk-upload.validation.address.unique"),
            },
          }
        : {
            name: "Company",
            alias: ["bedrijf", "organisatie", "business", "corporation"],
            rules: {
              required: t("page.user-bulk-upload.validation.company.required"),
              oneOf: {
                error: t("page.user-bulk-upload.validation.company.unknown"),
                values: companies!.items.map((x) => x.name),
              },
            },
          },
      {
        name: "First name",
        alias: ["voornaam", "voorletters"],
        rules: { required: t("page.user-bulk-upload.validation.first-name.required") },
      },
      {
        name: "Last name",
        alias: ["surname", "achternaam"],
        rules: { required: t("page.user-bulk-upload.validation.last-name.required") },
      },
      {
        name: "Email",
        alias: ["emailaddress", "mailadres"],
        rules: {
          required: t("page.user-bulk-upload.validation.email.required"),
          match: {
            error: t("page.user-bulk-upload.validation.email.invalid"),
            regex: /^(.+)@(.+)$/,
          },
          notOneOf: {
            error: t("page.user-bulk-upload.validation.email.existing"),
            values: users.filter((x) => isDefined(x.email)).map((x) => x.email!),
            ignoreCase: true,
          },
          unique: { error: t("page.user-bulk-upload.validation.email.repeated"), ignoreCase: true },
        },
      },
      {
        name: "Mobile",
        alias: ["phone number", "telefoonnummer"],
        rules: {
          match: {
            error: t("page.user-bulk-upload.validation.mobile.invalid"),
            regex: /^\+?\d+$/,
          },
        },
        transform: (s: string | number) => {
          if (typeof s === "number") {
            s = s.toString();
          }

          return s.replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "");
        },
      },
      {
        name: "Language",
        alias: ["taal"],
        defaultValue: languages[0].id,
        rules: {
          oneOf: {
            error: t("page.user-bulk-upload.validation.language.invalid"),
            values: languages.map((x) => x.id),
          },
        },
        valueAlias: {
          dutch: "nl",
          nederlands: "nl",
          german: "de",
          duits: "de",
          deutsch: "de",
          engels: "en",
          english: "en",
        },
        render(s: string) {
          return languageMap.get(s as any) || s;
        },
        transform: (s: string) => {
          if (typeof s === "string") {
            return s.toLowerCase();
          }

          return s;
        },
      },
    ] as const;

    return columns;
  }, [t, addresses, users, languages, companies, project.type]);

  async function upload(formData: FormValues) {
    if (!data) {
      throw new Error("Data missing");
    }

    if (!dataState?.valid) {
      throw new Error("Data is invalid");
    }

    try {
      setIsUploading(true);

      for (let idx = 0; idx < data.length; idx++) {
        const row = data[idx];
        if (row.uploadStatus === "SUCCESS") {
          continue;
        }

        updateValue(idx, "uploadStatus", "UPLOADING");
        const rowData = row as { [k in NonNullable<typeof columns>[number]["name"]]: string };
        try {
          await uploadUserAsync({
            email: rowData.Email,
            firstName: rowData["First name"],
            lastName: rowData["Last name"],
            language: rowData.Language as any,
            addressId: mapAddressValue(rowData.Address),
            companyId: mapCompanyValue(rowData.Company),
            mobileNumber: rowData["Mobile"],
            emailSignOffUserId: formData.signOffUser.id,
          });
          updateValue(idx, "uploadStatus", "SUCCESS");
        } catch (error) {
          updateValue(idx, "uploadStatus", "ERROR");
        }
      }
    } finally {
      setIsUploading(false);
    }
  }

  function mapAddressValue(addressName: string) {
    if (project.type !== "addressBased") {
      return;
    }

    if (!addresses) {
      throw new Error("No addresses available yet");
    }

    for (const address of addresses.items) {
      if (addressName === formatAddress(address)) {
        return address.id;
      }
    }
  }

  function mapCompanyValue(companyName: string) {
    if (project.type !== "companyBased") {
      return;
    }

    if (!companies) {
      throw new Error("No companies available yet");
    }

    for (const company of companies.items) {
      if (companyName === company.name) {
        return company.id;
      }
    }
  }

  function updateValue(row: number, column: string, value: string) {
    setData((oldData) => {
      if (!oldData) {
        throw new Error("No existing data");
      }

      const newData = [...oldData];
      const rowNumber = Number(row);
      const oldRow = oldData[rowNumber];
      const newRow = { ...oldRow, [column]: value };
      newData.splice(rowNumber, 1, newRow);

      return newData;
    });
  }

  function removeRow(row: number) {
    setData((oldData) => {
      if (!oldData) {
        return;
      }

      const newData = [...oldData];
      newData.splice(row, 1);

      // No more data remaining, reset form
      if (newData.length === 0) {
        return undefined;
      }

      return newData;
    });
  }

  const loadCsv = useCallback(
    async (...args: Parameters<typeof parseExcel>) => {
      try {
        setIsParsingFile(true);
        const rows = await parseExcel(...args);
        setData(parseData(rows as object[], columns!));
      } finally {
        setIsParsingFile(false);
      }
    },
    [columns],
  );

  useOnDropFiles(
    useCallback(
      (files) => {
        const file = files?.[0]?.getAsFile();
        if (file) {
          void file
            .text()
            .then(loadCsv)
            .catch((error) => alert(`Could not drop data: ${error}`));
        }
      },
      [loadCsv],
    ),
    !data && !isParsingFile,
  );

  useOnDropFiles(
    useCallback(
      async (files) => {
        const file = files?.[0].getAsFile();
        if (file) {
          try {
            const content = await parseFileContent(file);
            await loadCsv(content);
          } catch (error) {
            alert(error);
          }
        }
      },
      [loadCsv],
    ),
    !data && !isParsingFile,
  );

  useOnPaste(
    useCallback(
      (data) => {
        void loadCsv(data).catch((error) => alert(`Could not paste data: ${error}`));
      },
      [loadCsv],
    ),
    !data && !isParsingFile,
  );

  function downloadExampleExcel() {
    const columnNames = columns?.map((x) => x.name) || [];
    const locationLines =
      (project.type === "addressBased"
        ? addresses?.items.filter((x) => x.users.length === 0).map(formatAddress)
        : companies?.items.filter((x) => x.activeUserCount + (x.inactiveUserCount || 0) === 0).map((x) => x.name)) ||
      [];
    const fileName = `user-upload-${sessionUser.project.name}`;
    const data = [columnNames, ...locationLines.map((line) => [line])];

    return downloadExcel(fileName, data);
  }

  const onUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const file = target.files?.[0];

    if (file) {
      try {
        const contents = await parseFileContent(file);
        await loadCsv(contents);
      } catch (error) {
        alert(error);
        target.value = "";
      }
    }
  };

  const dataState = useMemo(() => (data && columns ? validate(columns, data) : undefined), [data, columns]);

  if (isLoadingAddresses || isLoadingUsers || isLoadingLanguages || isLoadingAdmins || isLoadingCompanies) {
    return <FullSizeLoader withPadding />;
  }

  const error = addressesError || usersError || languagesError || adminsError || companiesError;
  if (!columns || error) {
    return <ErrorPage error={error ?? t("page.user-bulk-upload.error.no-columns")} />;
  }

  return (
    <DocumentPaper
      title={t("page.user-bulk-upload.title")}
      theme="minimal"
      actions={
        data ? (
          <div className="flex h-10 items-center">
            <IconButton
              disabled={isUploading}
              title={t("page.user-bulk-upload.reset")}
              onClick={() => setData(undefined)}
            >
              <Icon name={iconX} />
            </IconButton>
          </div>
        ) : (
          <FileUploadButton
            disabled={isUploading}
            id="user_list_btn"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={onUploadFile}
          >
            {t("page.user-bulk-upload.button")}
          </FileUploadButton>
        )
      }
    >
      <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
        {
          // eslint-disable-next-line no-nested-ternary
          data ? (
            <div className="flex flex-col gap-8">
              <DataTable
                data={data}
                columns={columns}
                dataState={dataState!}
                onRemoveRow={removeRow}
                onUpdateValue={updateValue}
                disabled={isUploading}
                data-testid="users-data-table"
              />
              <Form formMethods={formMethods} onSubmit={upload}>
                <FormContent>
                  {sessionUser.isSuperAdmin ? (
                    <FormField htmlFor="sign-off-user" label={t("page.user-bulk-upload.form.sign-off-user")} required>
                      <FormSearchableSelect<FormValues, UserDto>
                        id="sign-off-user"
                        name="signOffUser"
                        items={admins}
                        keySelector={(x) => x.id}
                        renderOption={(x) => (
                          <div className="flex items-center text-left">
                            <div className="size-7">
                              <UserAvatar img={x.avatar} isUserDeleted={!!x.deletedAt} />
                            </div>
                            <p className="ml-2 text-caption text-black">{x.fullName}</p>
                          </div>
                        )}
                        rules={{
                          required: t("components.form.error.required", {
                            inputName: t("page.user-bulk-upload.form.sign-off-user"),
                          }),
                        }}
                        searchableFieldSelector={(x) => [x.fullName, x.email || ""]}
                      />
                    </FormField>
                  ) : null}
                  <Button
                    data-testid="submit-btn"
                    type="submit"
                    disabled={!dataState?.valid || data.every((x) => x.uploadStatus === "SUCCESS")}
                    isLoading={isUploading}
                  >
                    {t("page.user-bulk-upload.submit")}
                  </Button>
                </FormContent>
              </Form>
            </div>
          ) : isParsingFile ? (
            <FullSizeLoader withPadding />
          ) : (
            <>
              <p className="max-w-prose whitespace-pre-wrap">{t("page.user-bulk-upload.prose")}</p>
              <Button styling="secondary" onClick={downloadExampleExcel}>
                {t("page.user-bulk-upload.example-file")}
              </Button>
            </>
          )
        }
      </div>
    </DocumentPaper>
  );
}
