import { motion } from "framer-motion";
import type React from "react";

interface VideoPreviewProgressIndicatorProps {
  progress: number;
}

export function VideoPreviewProgressIndicator({ progress }: VideoPreviewProgressIndicatorProps): React.ReactNode {
  return (
    <div className="flex h-4 w-full items-center">
      <div className="relative flex h-2 w-full overflow-hidden rounded-lg bg-grey-300">
        <motion.span
          initial={{ x: "-100%" }}
          animate={{ x: `${progress - 100}%` }}
          transition={{ ease: "linear" }}
          style={{
            backgroundSize: "200%",
          }}
          className="size-full animate-background-shift-x rounded-lg bg-gradient-to-r from-green-400 to-green-600"
        />
      </div>
    </div>
  );
}
