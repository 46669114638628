import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ConstraintListItemDto, LanguageDto, ServicePartnerCreateRequest, ServicePartnerRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { createFormTranslations, useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createFormPermissions, toPermissionsRequest } from "helpers/permissions";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canCreateNew } from "modules/service-partners/permissions";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

import type { CreateOrEditFormFields, LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const sessionUser = useSessionUser();
  const projectId = useProjectId();
  const slug = useSlug();
  const { id: serviceId } = useParams<{ id: string }>();
  const isEditMode = serviceId != null;
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const [isSubmitting, submittingHandler] = useBool(false);
  const hasPermission = usePermission();

  const { data: languages = [], isFetching: isLanguagesFetching, error: languagesError } = useLanguages();
  const {
    data: categories = [],
    isFetching: isCategoriesFetching,
    error: categoriesError,
  } = useQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNERS_CATEGORIES(projectId),
    queryFn: () => api.getServicepartnersCategoriesV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: serviceTypesData,
    isFetching: isServiceTypesFetching,
    error: serviceTypesError,
  } = useQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNERS_CREATE_TYPES(projectId),
    queryFn: () =>
      api.getServicepartnersV1({
        returnAllowedTypes: true,
        Limit: 0,
      }),
    select: commonAPIDataSelector,
    enabled: !isEditMode,
  });

  const {
    data: serviceDetails,
    isFetching: isServiceFetching,
    error: servicePartnerError,
  } = useQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNER(projectId, serviceId!),
    queryFn: () => api.getServicepartnersDetailsV1(serviceId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
    gcTime: 0,
  });

  const { mutate: createService } = useMutation({
    mutationFn: api.postServicepartnersV1,
    onSuccess: (data) => {
      submittingHandler.setFalse();
      showFlashToast({
        type: "success",
        title: t("model.service.action.create.notification.success"),
      });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL(projectId) });

      navigate(routes.servicePartners.details({ slug, id: data.data.id }));
    },
    onError: () => {
      submittingHandler.setFalse();
      showFlashToast({
        type: "error",
        title: t("model.service.action.create.notification.error"),
      });
    },
  });
  const { mutate: editService } = useMutation({
    mutationFn: ({ id, body }: { id: string; body: ServicePartnerRequest }) => api.putServicepartnersV1(id, body),
    onSuccess: async (_, payload) => {
      submittingHandler.setFalse();
      showFlashToast({
        type: "success",
        title: t("model.service.action.edit.notification.success"),
      });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL(projectId) });
      navigate(routes.servicePartners.details({ slug, id: payload.id }));
    },
    onError: () => {
      submittingHandler.setFalse();
      showFlashToast({
        type: "error",
        title: t("model.service.action.edit.notification.error"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL(projectId) });
    },
  });
  const { uploadFormImage } = useUploadImage();
  const {
    data: roles = [],
    isFetching: isRolesFetching,
    error: rolesError,
  } = useQuery({
    queryKey: QUERY_KEYS.PERMISSION_ROLES(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
    enabled: sessionUser.isAdmin,
  });
  const {
    data: defaultsServicesPermissions = [],
    isFetching: isDefaultPermissionsFetching,
    error: servicePartnersDefaultPermissionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNERS_DEFAULT_PERMISSIONS(projectId),
    queryFn: () => api.getPermissionsDefaultServicePageV1(),
    select: commonAPIDataSelector,
    enabled: sessionUser.isAdmin,
  });
  const connectionsQuery = { Limit: 1000, Offset: 0 };
  const {
    data: connections,
    isFetching: isConnectionsFetching,
    error: connectionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_CONNECTION_LIST(connectionsQuery),
    queryFn: () => api.getProjectConnectionsV1(connectionsQuery),
    select: commonAPIDataSelector,
    enabled: sessionUser.isSuperAdmin,
  });

  const onSubmit = useCallback(
    async function (data: CreateOrEditFormFields) {
      submittingHandler.setTrue();

      if (data.profileImage == null || data.profileImage.length == 0 || data.category?.id == null) {
        showFlashToast({
          type: "error",
          title: t("model.service.action.create.notification.error"),
        });

        return;
      }

      let audience: ConstraintListItemDto[] = [];
      if (!data.connection && sessionUser.isAdmin && data.type !== "iqbi") {
        audience = data.audience;
      }

      const profileImage = await uploadFormImage(data.profileImage[0]);

      if (!profileImage) return;

      const payload: ServicePartnerRequest | ServicePartnerCreateRequest = {
        translations: Object.entries(data.titleTranslations).map((x) => ({
          languageId: x[0] as LanguageDto["id"],
          title: x[1],
          subtitle: data.subtitleTranslations[x[0] as keyof typeof data.subtitleTranslations] || "",
          description: data.descriptionTranslations[x[0] as keyof typeof data.descriptionTranslations] || "",
        })),
        audience,
        projectConnectionId: data.connection?.id,
        category: data.category.id,
        email: data.email,
        phoneNumber: data.phoneNumber,
        profileImageId: profileImage.id,
        permissions: sessionUser.isAdmin ? toPermissionsRequest(data.permissions) : [],
        type: data.type,
      };

      if (data.headerImage && data.headerImage.length > 0) {
        const headerImage = await uploadFormImage(data.headerImage[0]);

        payload.headerImageId = headerImage?.id;
      }

      if (isEditMode) {
        editService({ id: serviceId, body: payload });
      } else {
        createService({
          ...payload,
        });
      }
    },
    [
      submittingHandler,
      sessionUser.isAdmin,
      uploadFormImage,
      isEditMode,
      showFlashToast,
      t,
      editService,
      serviceId,
      createService,
    ],
  );

  const canEdit = serviceDetails && serviceDetails.canEdit;
  const accessGranted = isEditMode ? canEdit : hasPermission(canCreateNew);

  const error =
    servicePartnerError ||
    languagesError ||
    categoriesError ||
    rolesError ||
    servicePartnersDefaultPermissionsError ||
    connectionsError ||
    serviceTypesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isPageLoading =
    isLanguagesFetching ||
    isCategoriesFetching ||
    isDefaultPermissionsFetching ||
    isRolesFetching ||
    isServiceFetching ||
    isConnectionsFetching ||
    isServiceTypesFetching;
  if (isPageLoading) {
    return <FullSizeLoader withPadding />;
  }

  if (!accessGranted) {
    return <ErrorPage status={403} />;
  }

  return (
    <>
      {props.children({
        isEditMode,
        languages,
        categories,
        isSubmitting: isSubmitting,
        connections: connections?.items ?? [],
        allowedTypes: serviceTypesData?.allowedTypes || [],
        defaultValues: {
          titleTranslations: createFormTranslations({ languages, translations: serviceDetails?.titleTranslations }),
          subtitleTranslations: createFormTranslations({
            languages,
            translations: serviceDetails?.subtitleTranslations,
          }),
          descriptionTranslations: createFormTranslations({
            languages,
            translations: serviceDetails?.descriptionTranslations?.map(({ languageId, value }) => ({
              languageId,
              value: value,
            })),
          }),
          audience: serviceDetails?.audience || [],
          connection: serviceDetails?.connection
            ? connections?.items.find((c) => c.id === serviceDetails.connection?.id)
            : undefined,
          category: serviceDetails?.category,
          permissions: createFormPermissions(roles, defaultsServicesPermissions, serviceDetails?.permissions),
          email: serviceDetails?.email,
          headerImage: serviceDetails?.headerImage ? [serviceDetails.headerImage] : [],
          profileImage: serviceDetails?.profileImage ? [serviceDetails.profileImage] : [],
          phoneNumber: serviceDetails?.phoneNumber,
          type: serviceDetails?.type || "general",
        },
        onSubmit,
      })}
    </>
  );
}
