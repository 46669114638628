import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { QuickReplyRepairRequestCommentDto, UserDto } from "api/types";
import iconLock01 from "assets/icons/lock-01.svg";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Pdf } from "components/Pdf/Pdf";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { VideoPreview } from "components/VideoPreview/VideoPreview";
import { parseISO } from "date-fns";
import { motion } from "framer-motion";
import { stripTime } from "helpers/date";
import { useBool } from "hooks/useBool";
import { groupBy, sortBy } from "lodash-es";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface TicketActivityWrapperProps {
  postedAt: string;
  author: UserDto;
}

export function TicketActivityWrapper({
  postedAt,
  author,
  children,
}: React.PropsWithChildren<TicketActivityWrapperProps>): React.ReactNode {
  return (
    <article className="my-2 flex rounded-3px p-2 pb-0">
      <div className="mr-2 size-8">
        <UserAvatar img={author.avatar} isUserDeleted={!!author.deletedAt} />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className="flex items-start">
          <div className="flex flex-wrap items-center">
            <span className="mr-2 truncate text-body-bold text-black">{author.fullName}</span>
            <span className="text-caption text-grey-500">
              <FormattedDate date={postedAt} format="time" />
            </span>
          </div>
        </div>

        <p className="my-1 whitespace-pre-line rounded-3px border border-grey-300 p-2 italic">{children}</p>
      </div>
    </article>
  );
}

interface TicketCommentProps {
  token: string;
  languageId: string;
  isReadonly?: boolean;
  comment: QuickReplyRepairRequestCommentDto;
  isOwn: boolean;
  onEdit?: (comment: QuickReplyRepairRequestCommentDto) => void;
}

export function TicketComment({ token, languageId, comment, isOwn, onEdit }: TicketCommentProps): React.ReactNode {
  const { t } = useTranslation();

  // User should only be allowed to modify his own post messages or repair request notes
  const isNote = comment.internal;
  const api = useApi();
  const showFlashToast = useFlashToast();

  const [hideTranslation, hideTranslationHandlers] = useBool(true);
  const translation = useQuery({
    queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITY_TRANSLATION(token, comment.id),
    queryFn: () => api.getQuickReplyRepairRequestActivitiesTranslationsDetailsV1(token, comment.id, languageId),
    retry: false,
    enabled: false,
  });

  const hasTranslation = translation.data && !hideTranslation;
  const translateButton =
    comment.content &&
    comment.languageIsoCode !== languageId &&
    !isOwn &&
    !comment.deletedAt &&
    !isNote &&
    !comment.isAutoReply ? (
      <Button
        styling="ghostPrimary"
        isLoading={translation.isLoading}
        onClick={async () => {
          hideTranslationHandlers.toggle();

          if (!translation.data) {
            try {
              await translation.refetch();
            } catch (error) {
              showFlashToast({ type: "error", title: t("page.tickets.details.activities.comment.translate.failed") });
            }
          }
        }}
      >
        {hasTranslation
          ? t("page.tickets.details.activities.comment.translate.original")
          : t("page.tickets.details.activities.comment.translate")}
      </Button>
    ) : null;

  const content =
    // eslint-disable-next-line no-nested-ternary
    comment.deletedAt && !comment.content
      ? isNote
        ? t("component.community-post-comments.note-deleted")
        : t("component.community-post-comments.comment-deleted")
      : hasTranslation
        ? translation.data.data.content
        : comment.content;

  return (
    <motion.article
      className={twJoin(
        "my-2 flex rounded-3px p-2 pb-0",
        comment.deletedAt && "text-grey-400",
        isNote ? "mr-2 bg-yellow-100 pb-2" : "first:pt-0",
      )}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, height: 0, marginBottom: "0px" }}
      data-testid="comment-root"
    >
      <div className={twJoin("mr-2 size-8", comment.deletedAt ? "opacity-40" : undefined)}>
        <UserAvatar img={comment.author.avatar} isUserDeleted={!!comment.author.deletedAt} />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className={twJoin("flex items-start", comment.deletedAt ? "text-grey-400" : undefined)}>
          <div className="flex flex-wrap items-center">
            <span
              className={twJoin("mr-2 truncate text-body-bold", comment.deletedAt ? "text-grey-400" : "text-black")}
            >
              {comment.author.fullName}
            </span>

            <span className="text-caption text-grey-500">
              <FormattedDate date={comment.postedAt} format="time" />
            </span>

            {comment.updatedAt && (
              <Tooltip tooltip={<FormattedDate date={comment.updatedAt} format="datetime" />}>
                <span className="relative ml-1 cursor-default text-caption italic text-grey-500 after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-px after:w-full after:bg-grey-500 after:content-[''] hover:after:block">
                  ({t("model.repair-request.comment-edited")})
                </span>
              </Tooltip>
            )}
          </div>

          {isNote && (
            <span className="ml-auto mr-1 flex h-6 items-center text-caption text-yellow-800 md:mr-3">
              <Icon name={iconLock01} className="mr-1 block" />
              <span className="hidden text-caption sm:block">{t("model.repair-request.comment-note")}</span>
            </span>
          )}
        </div>

        <div className="flex w-full flex-col items-start gap-2">
          {content && (
            <p
              className={twJoin(
                "max-w-prose whitespace-pre-line",
                comment.deletedAt && comment.content && "line-through",
              )}
              data-testid="comment-content"
            >
              <LinkFormatter>{content}</LinkFormatter>
              {translateButton}
            </p>
          )}

          {/* Attachments */}
          {comment.images.length > 0 && (
            <div className="max-w-full" data-testid="comment-attachment-image">
              <Gallery images={comment.images} isDeleted={!!comment.deletedAt} />
            </div>
          )}
          {comment.videos.length > 0 && (
            <div className="max-w-full" data-testid="comment-attachment-video">
              <VideoPreview video={comment.videos[0]} />
            </div>
          )}
          {comment.documents.length > 0 && (
            <div className="max-w-full" data-testid="comment-attachment-document">
              <Pdf
                fileName={comment.documents[0].fileName}
                onClick={() => window.open(comment.documents[0].url, "_blank")}
              />
            </div>
          )}

          {isNote && isOwn && onEdit && (
            <div className="max-w-full">
              <Button styling="ghostSecondary" size="sm" onClick={() => onEdit(comment)}>
                {t("common.action.edit")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </motion.article>
  );
}

export function groupActivities<T extends { postedAt: string }>(activities: T[]): [string, T[]][] {
  const sortedComments = sortBy(activities, (x) => parseISO(x.postedAt).valueOf());
  const groupedByDay = Object.entries(groupBy(sortedComments, (x) => stripTime(x.postedAt).valueOf()));

  return sortBy(groupedByDay, ([date]) => Number(date));
}
