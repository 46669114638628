import { useQuery } from "@tanstack/react-query";
import type { MessageCreateRequest } from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useCommunityFeedQueries } from "queries/communityFeed";
import { communityFeedMutations } from "queries/communityFeed/mutations";
import { generalMutations } from "queries/general";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export type OptimizeType = "refresh" | "makeShorter" | "makeMorePositive" | "makeUnderstandable";

export function Loader({ children }: LoaderProps): React.ReactNode {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state: locationState } = useLocation();

  const communityFeedQueries = useCommunityFeedQueries();
  const {
    data: postAudience,
    isFetching: isLoadingAudience,
    error: errorLoadingAudience,
  } = useQuery(communityFeedQueries.audience({ IncludeAnnouncements: true }));
  const optimizePost = generalMutations.useGetCopilotResponse();
  const createMessage = communityFeedMutations.useAddMessage();

  const { uploadFormImage } = useUploadImage();
  const { uploadFormVideo } = useUploadVideo({});
  const { uploadFormDocument } = useUploadDocument();

  const onSubmit = async ({
    payload,
    images,
    documents,
    videos,
  }: {
    payload: MessageCreateRequest;
    images: FormImage[];
    documents: FormDocument[];
    videos: FormVideo[];
  }) => {
    setIsSubmitting(true);

    const imageUploadPromises = images.map((image) => uploadFormImage(image));
    // Curently only 1 document is allowed
    const documentUploadPromises = documents.map((document) => uploadFormDocument(document));
    // Curently only 1 video is allowed
    const videoUploadPromises = videos.map((video) => uploadFormVideo(video));

    const imageIds = (await Promise.allSettled(imageUploadPromises))
      .map((image) => (image.status === "fulfilled" ? image.value?.id : ""))
      .filter(Boolean) as string[];
    const documentIds = (await Promise.allSettled(documentUploadPromises))
      .map((document) => (document.status === "fulfilled" ? document.value?.id : ""))
      .filter(Boolean) as string[];
    const videoIds = (await Promise.allSettled(videoUploadPromises.map((video) => video)))
      .map((video) => (video.status === "fulfilled" ? video.value?.id : ""))
      .filter(Boolean) as string[];

    // Only create message when all attachments were uploaded successfully
    if (
      imageIds.length === images.length &&
      documentIds.length === documents.length &&
      videoIds.length === videos.length
    ) {
      return await createMessage.mutateAsync({
        payload: { ...payload, imageIds, documentIds, videoIds },
      });
    }

    setIsSubmitting(false);
  };

  const defaultGroupFromUrl = useMemo(() => {
    if (!locationState || !("groupId" in locationState)) {
      return undefined;
    }

    if (!postAudience) {
      return undefined;
    }

    const interestGroup = postAudience.interestGroups.find((group) => group.id === locationState.groupId);
    if (interestGroup) {
      return interestGroup;
    }

    const helpCategory = postAudience.helpCategories.find((group) => group.id === locationState.groupId);
    if (helpCategory) {
      return helpCategory;
    }

    const realEstateGroup = postAudience.realEstateGroups.find((group) => group.id === locationState.groupId);
    if (realEstateGroup) {
      return realEstateGroup;
    }

    return undefined;
  }, [postAudience, locationState]);

  const error = errorLoadingAudience;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingAudience;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    postAudience: postAudience!,
    optimizePost: optimizePost.mutateAsync,
    isOptimizingPost: optimizePost.isPending,
    optimizingPostSetting: optimizePost.variables?.option,
    onSubmit,
    isSubmitting,
    defaultFormValues: {
      type: "undefined",
      title: "",
      body: "",
      scheduled: "",
      images: [],
      documents: [],
      videos: [],
      group: defaultGroupFromUrl,
      groupAudience: defaultGroupFromUrl
        ? defaultGroupFromUrl.selectableAudience.find((x) => x.type === defaultGroupFromUrl.defaultPostingLevel) ||
          defaultGroupFromUrl.selectableAudience[0]
        : undefined,
    },
  });
}
