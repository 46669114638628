import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ResidentGroupCreateRequest, ResidentGroupEditRequest } from "api/types";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "routes";

const NAME_MAX_LENGTH = 40;
const DESCRIPTION_MAX_LENGTH = 140;

export function CreateOrEditResidentGroupPage(): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { id: groupId } = useParams<{ id: string }>();
  const isEditMode = groupId != null;

  const predefinedGroupId = groupId;

  const {
    data: groupDetails,
    isFetching: groupDetailsIsFetching,
    error: groupDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, predefinedGroupId!),
    queryFn: () => api.getGroupsDetailsV2(predefinedGroupId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
    gcTime: 0,
  });

  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { mutate: editGroup, isPending: isSubmittingEdit } = useMutation({
    mutationFn: (payload: ResidentGroupEditRequest) =>
      api.putResidentGroupsV2(groupId!, payload).then(({ data }) => data),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, groupId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: (data) => {
      showFlashToast({
        type: "success",
        title: t("page.create-resident-group.edit.notification.success"),
        "data-testid": "toast-group-is-edited",
      });
      navigate(routes.interestGroups.details({ slug, id: data.id }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.create-resident-group.edit.notification.failed"),
      });
    },
  });

  const { mutate: createGroup, isPending: isSubmittingCreate } = useMutation({
    mutationFn: (payload: ResidentGroupCreateRequest) => api.postResidentGroupsV2(payload).then(({ data }) => data),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: (data) => {
      showFlashToast({
        type: "success",
        title: t("page.create-resident-group.create.notification.success"),
        "data-testid": "toast-group-is-created",
      });
      navigate(routes.interestGroups.details({ slug, id: data.id }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.create-resident-group.create.notification.failed"),
      });
    },
  });

  async function onSubmit(request: CreateOrEditFormFields) {
    if (request.image.length === 0) {
      showFlashToast({
        type: "error",
        title: t("page.create-resident-group.create.notification.failed"),
      });

      throw new Error("Image not provided");
    }

    const uploadedImage = await uploadFormImage(request.image[0]);

    if (uploadedImage) {
      const payload: ResidentGroupEditRequest | ResidentGroupCreateRequest = {
        name: request.name,
        description: request.description,
        imageId: uploadedImage.id,
      };

      if (isEditMode) {
        editGroup(payload);
      } else {
        createGroup(payload);
      }
    }
  }

  const isSubmitting = isSubmittingEdit || isSubmittingCreate || isUploadingImage;

  const defaultValues = {
    name: groupDetails?.name,
    description: groupDetails?.description,
    image: groupDetails?.image ? [groupDetails.image] : [],
  };

  if (isEditMode && groupDetails && !groupDetails.canEdit) {
    return <ErrorPage status={403} />;
  }

  if (isEditMode && groupDetails && !groupDetails.isResidentGroup) {
    return <ErrorPage status={403} />;
  }

  const error = groupDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isPageLoading = groupDetailsIsFetching;
  if (isPageLoading) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <DocumentPaper
      theme="constrained"
      title={isEditMode ? t("page.create-resident-group.edit.title") : t("page.create-resident-group.create.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.group-details.breadcrumbs.discovery"),
            },
            {
              name: t("page.interest-group.list.title"),
              to: routes.interestGroups.list({ slug }),
            },
            isEditMode ? { name: groupDetails?.name || "" } : { name: t("page.create-resident-group.create.title") },
          ]}
        />
      }
    >
      <InternalForm
        defaultValues={defaultValues}
        isEditMode={isEditMode}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </DocumentPaper>
  );
}

function InternalForm({
  defaultValues,
  isEditMode,
  isSubmitting,
  onSubmit,
}: {
  defaultValues: Partial<CreateOrEditFormFields>;
  isEditMode: boolean;
  isSubmitting: boolean;
  onSubmit: (data: CreateOrEditFormFields) => void;
}) {
  const { t } = useTranslation();
  const formMethods = useForm<CreateOrEditFormFields>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormContent>
        <FormField label={t("page.create-resident-group.form.name")} required>
          <FormInput<CreateOrEditFormFields, "name">
            name="name"
            placeholder={t("page.create-resident-group.form.name.placeholder")}
            rules={{
              validate: {
                required: createRequiredStringRule(t, "page.create-resident-group.form.name"),
              },
              maxLength: {
                message: t("components.form.error.max-length", {
                  length: NAME_MAX_LENGTH,
                }),
                value: NAME_MAX_LENGTH,
              },
            }}
          />
        </FormField>
        <FormField label={t("page.create-resident-group.form.description")} required>
          <FormTextArea<CreateOrEditFormFields, "description">
            name="description"
            placeholder={t("page.create-resident-group.form.description.placeholder")}
            rules={{
              validate: {
                required: createRequiredStringRule(t, "page.create-resident-group.form.description"),
              },
              maxLength: {
                message: t("components.form.error.max-length", {
                  length: DESCRIPTION_MAX_LENGTH,
                }),
                value: DESCRIPTION_MAX_LENGTH,
              },
            }}
          />
        </FormField>
        <FormField label={t("page.create-resident-group.form.icon")} required>
          <FormImageInput<CreateOrEditFormFields, "image">
            name="image"
            rules={{
              required: t("components.form.error.required", {
                inputName: t("page.create-resident-group.form.icon"),
              }),
            }}
          />
        </FormField>
        <Button type="submit" isLoading={isSubmitting} data-testid="submit-btn">
          {isEditMode ? t("page.create-resident-group.edit.submit") : t("page.create-resident-group.create.submit")}
        </Button>
      </FormContent>
    </Form>
  );
}

export interface CreateOrEditFormFields {
  name: string;
  description: string;
  image: FormImage[];
}
