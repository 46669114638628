import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CreateEventRequest, EventCategoryDto, EventDetailsDto, LanguageDto, UpdateEventRequest } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { parseISO } from "date-fns";
import { createFormTranslations, toTranslationsRequest } from "helpers/languages";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

import type { LayoutFormValues } from "./Layout";
import type { EventObserver } from "./Loader";

function getMaxParticipants(noLimit?: boolean, maxParticipants?: string): CreateEventRequest["maxParticipants"] {
  if (noLimit) {
    return undefined;
  }
  if (maxParticipants == null) {
    return undefined;
  }
  if (maxParticipants === "") {
    return undefined;
  }

  return +maxParticipants;
}

export function getDefaultFormValues(
  isClone: boolean,
  languages: LanguageDto[],
  eventCategories: EventCategoryDto[],
  event: EventDetailsDto | undefined,
  eventObservers: EventObserver[],
): LayoutFormValues {
  return {
    nameTranslations: createFormTranslations({ languages, translations: event?.nameTranslations }),
    descriptionTranslations: createFormTranslations({ languages, translations: event?.descriptionTranslations }),
    location: event?.location || "",
    start: event?.startsAt == null ? "" : parseISO(event.startsAt),
    end: event?.endsAt == null ? "" : parseISO(event.endsAt),
    category:
      (event?.category?.id ? eventCategories.find(({ id }) => id === event.category.id) : undefined) ??
      eventCategories[0],
    maxParticipants: event?.maxParticipants?.toString() || "",
    noParticipantsLimit: !event?.maxParticipants && event?.participantCount != null,
    image: event?.image ? [event.image] : [],
    type: event?.type ?? "common",
    participantCount: isClone ? 0 : event?.participantCount ?? 0,
    audience: event?.audience || [],
    eventObserver: event?.projectConnection
      ? eventObservers.find((p) => p.id === event.projectConnection?.id)!
      : eventObservers[0],
    publishAt: !event?.publishedAt && event?.publishAt ? parseISO(event.publishAt) : "",
  };
}

export function useCreateEvent(): { isSubmitting: boolean; create: (data: LayoutFormValues) => void } {
  const projectId = useProjectId();
  const slug = useSlug();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { mutate: createEvent, isPending: isSubmitting } = useMutation({
    mutationFn: (body: CreateEventRequest) => api.postEventsV1(body).then(({ data }) => data),
    onSuccess: (data) => {
      showFlashToast({
        type: "success",
        title: t("page.event.create-or-edit.create-mode.action.create.notification.success"),
      });
      navigate(routes.events.details({ slug, id: data.id }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.event.create-or-edit.create-mode.action.create.notification.failure"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_LIST_ALL(projectId), refetchType: "none" });
    },
  });

  const create = useCallback(
    async (formValues: LayoutFormValues) => {
      if (!formValues.start) {
        throw new Error("Missing start date");
      }

      if (!formValues.end) {
        throw new Error("Missing end date");
      }

      const payload: CreateEventRequest = {
        startsAt: formValues.start.toISOString(),
        endsAt: formValues.end.toISOString(),
        location: formValues.location?.trim() || undefined,
        maxParticipants: getMaxParticipants(formValues.noParticipantsLimit, formValues.maxParticipants),
        nameTranslations: toTranslationsRequest(formValues.nameTranslations),
        descriptionTranslations: toTranslationsRequest(formValues.descriptionTranslations),
        type: formValues.type,
        categoryType: formValues.category.id,
        audience: formValues.audience,
        projectConnectionId: formValues.eventObserver?.type === "project" ? undefined : formValues.eventObserver.id,
        publishAt: formValues.publishAt ? formValues.publishAt.toISOString() : undefined,
      };

      if (formValues.image.length > 0) {
        const uploadedImage = await uploadFormImage(formValues.image[0]);

        payload.imageId = uploadedImage?.id;
      }

      createEvent(payload);
    },
    [createEvent, uploadFormImage],
  );

  return {
    isSubmitting: isSubmitting || isUploadingImage,
    create,
  };
}

export function useEditEvent(eventId: string): { isSubmitting: boolean; edit: (data: LayoutFormValues) => void } {
  const projectId = useProjectId();
  const slug = useSlug();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { mutate: editEvent, isPending: isSubmitting } = useMutation({
    mutationFn: (body: UpdateEventRequest) => api.putEventsV1(eventId, body).then(({ data }) => data),
    onSuccess: (data) => {
      showFlashToast({
        type: "success",
        title: t("page.event.create-or-edit.edit-mode.action.edit.notification.success"),
      });
      navigate(routes.events.details({ slug, id: data.id }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.event.create-or-edit.edit-mode.action.edit.notification.failure"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_LIST_ALL(projectId), refetchType: "none" });
    },
  });

  const edit = useCallback(
    async (formValues: LayoutFormValues) => {
      if (!formValues.start) {
        throw new Error("Missing start date");
      }

      if (!formValues.end) {
        throw new Error("Missing end date");
      }

      const payload: UpdateEventRequest = {
        startsAt: formValues.start.toISOString(),
        endsAt: formValues.end.toISOString(),
        location: formValues.location?.trim() || undefined,
        maxParticipants: getMaxParticipants(formValues.noParticipantsLimit, formValues.maxParticipants),
        nameTranslations: toTranslationsRequest(formValues.nameTranslations),
        descriptionTranslations: toTranslationsRequest(formValues.descriptionTranslations),
        categoryType: formValues.category.id,
        audience: formValues.audience,
        publishAt: formValues.publishAt ? formValues.publishAt.toISOString() : undefined,
      };

      if (formValues.image.length > 0) {
        const image = await uploadFormImage(formValues.image[0]);

        payload.imageId = image?.id;
      }

      editEvent(payload);
    },
    [editEvent, uploadFormImage],
  );

  return {
    isSubmitting: isSubmitting || isUploadingImage,
    edit,
  };
}
